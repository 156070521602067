import React, { StrictMode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { CacheProvider } from '@emotion/react';
import { ContextState } from '/context';

import MoreLayout from '../layouts/MoreLayout';
import theme from 'styles/theme';
import '../styles/globals.css';

import Head from 'next/head';
import Router from 'next/router';
import PropTypes from 'prop-types';

import createEmotionCache from '../utils/createEmotionCache';

import ProgressBar from '@badrap/bar-of-progress';

const progress = new ProgressBar({
    size: 3,
    color: '#373A46',
    className: 'bar-of-progress',
    delay: 100,
});

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
    const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;
    const Layout = Component.Layout || MoreLayout;

    return (
        <StrictMode>
            <React.Fragment>
                <CacheProvider value={emotionCache}>
                    <Head>
                        <title>Olympiad Mongolia</title>
                        <link rel="shortcut icon" href="/logo-new.png" />
                        <link
                            href="//cdnjs.cloudflare.com/ajax/libs/KaTeX/0.9.0/katex.min.css"
                            rel="stylesheet"
                        />
                        <meta charSet="utf-8" />
                        <meta property="og:title" content="Olympiad Mongolia" key="title" />
                        <meta property="og:image" content="/logo-new.png" key="ogimage" />
                    </Head>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <ContextState>
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        </ContextState>
                    </ThemeProvider>
                </CacheProvider>
            </React.Fragment>
        </StrictMode>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
    emotionCache: PropTypes.object,
};
