import React, { useState, useEffect } from 'react';

const Timer = (props) => {
    const [duration, setDuration] = useState(props.duration);

    useEffect(() => {
        const interval = setInterval(() => {
            setDuration((prev) => {
                if (prev === 0) {
                    props.onEnd && props.onEnd();
                    clearInterval(interval);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div
            style={{
                backgroundColor: '#ffb8b8',
                padding: '0px 10px 0px 10px',
                borderRadius: 4,
                color: 'red',
                fontWeight: 600,
            }}
            className="timer-box"
        >
            <div className="time">{formatRender(duration)}</div>
        </div>
    );
};

const pad = (input) => {
    const Base = '00';

    return input ? Base.substr(0, 2 - Math.ceil(input / 9)) + input : Base;
};

const formatRender = (duration) => {
    const minute = Math.floor(duration / 60);
    const second = duration % 60;
    return `${pad(minute)}:${pad(second)}`;
};

Timer.defaultProps = {
    duration: 0,
};

export default Timer;
