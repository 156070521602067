const authHeader = (isfile) => {
    let user;
    if (process.browser) {
        user = JSON.parse(localStorage.getItem('user'));
    }

    const token = user ? user.authToken : null;

    if (token) {
        if (isfile) {
            return {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            };
        } else {
            return {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };
        }
    }
    if (isfile) {
        return {
            Accept: 'application/json',
        };
    } else {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }
};

const setHeader = (method = 'GET', isfile = false) => {
    if (method === 'GET') {
        return {
            method: method,
            headers: authHeader(),
        };
    } else {
        return {
            method: method,
            // credentials: "include",
            headers: authHeader(isfile),
        };
    }
};

export const get = async (target) => {
    const options = setHeader();
    const response = await fetch(process.env.NEXT_PUBLIC_HOSTNAME + target, options);
    const result = await response.json();

    return result;
};

export const put = async (target, datas) => {
    const options = setHeader('PUT');
    options.body = JSON.stringify(datas);
    const response = await fetch(process.env.NEXT_PUBLIC_HOSTNAME + target, options);
    const jsonData = await response.json();
    return jsonData;
};

export const post = async (target, data) => {
    const options = setHeader('POST');
    options.body = JSON.stringify(data);
    const response = await fetch(process.env.NEXT_PUBLIC_HOSTNAME + target, options);
    const result = await response.json();
    return result;
};

export const destroy = async (target, datas) => {
    const options = setHeader('DELETE');
    options.body = JSON.stringify(datas);
    const response = await fetch(process.env.NEXT_PUBLIC_HOSTNAME + target, options);
    const jsonData = await response.json();
    return jsonData;
};

export const upload = async (target, data) => {
    const options = setHeader('POST', true);
    options.body = data;
    const response = await fetch(process.env.NEXT_PUBLIC_HOSTNAME + target, options);
    const jsonData = await response.json();
    return jsonData;
};
