import { get, post, put } from 'utils/fetchRequest';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const createAccount = async (data) => {
    const result = await post('accounts', data);
    return result;
};

export const createFireBaseToken = async (data) => {
    const result = await post('accounts/set_firebase_token', data);
    return result;
};

export const createPassword = async (dispatch, data) => {
    try {
        const result = await post('accounts/set_password', data);
        if (result.code === 200) dispatch({ type: LOGIN_SUCCESS, data: result.data });
        else dispatch({ type: LOGIN_ERROR });

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const confirmEmailCode = async (data) => {
    try {
        const result = await post('accounts/confirm_register_code', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getStudents = async (query) => {
    try {
        const result = await get('accounts/students' + query);
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const me = async () => {
    try {
        const result = await get('accounts/me');
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const checkProfile = async () => {
    try {
        const result = await get('accounts/profile_check');
        return result.data;
    } catch (err) {
        return { error: err };
    }
};

export const updateUser = async (datas) => {
    try {
        const result = await put(`accounts`, datas);
        return result;
    } catch (err) {
        return { error: err };
    }
};
