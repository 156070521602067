import Cookies from 'js-cookie';
import { LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from 'context/actions/auth';

let user = null;

if (process.browser) {
    user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
}

const initialState = user ? { loggedIn: true, user } : { loggedIn: false };

const reducer = (state, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            Cookies.set('auth_token', action.data.authToken, { expires: 7 });
            localStorage.setItem('user', JSON.stringify(action.data));
            return {
                loggedIn: true,
                user: action.data,
            };
        case LOGIN_ERROR:
            localStorage.clear('user');
            Cookies.remove('auth_token');
            return { loggedIn: false };
        case LOGOUT:
            localStorage.clear('user');
            Cookies.remove('auth_token');
            return { loggedIn: false };
        default:
            return state;
    }
};

const authReducer = [reducer, initialState];

export default authReducer;
