import combineReducers from 'react-combine-reducers';
import auth from './auth';
import packageReducer from './package';
import olympiadReducer from './olympiad';

const [reducer, initialState] = combineReducers({
    auth,
    packageReducer,
    olympiadReducer,
});

export { reducer, initialState };
