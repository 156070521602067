const typography = {
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto, sans-serif',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    h1: {
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.24px',
        color: 'black',
    },
    h2: {
        fontSize: 24,
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        color: 'black',
        letterSpacing: '-0.24px',
    },
    h3: {
        fontSize: 16,
        fontWeight: 600,
        fontWeight: 'bold',
        color: 'black',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.06px',
    },
    h4: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '-0.05px',
        fontFamily: 'Roboto, sans-serif',
        color: 'black',
    },
    h5: {
        fontSize: 13,
        fontWeight: 'normal',
        letterSpacing: '-0.05px',
        fontFamily: 'Roboto, sans-serif',
    },
    h6: {
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '-0.05px',
        fontFamily: 'Roboto, sans-serif',
        color: 'black',
    },
    overline: {
        fontWeight: 500,
    },
    body1: {
        fontSize: 24,
        fontWeight: 400,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body2: {
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    subtitle1: {
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
        color: '#899AB9',
    },
    subtitle2: {
        fontSize: 25,
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.05px',
        color: 'black',
        // margin: '20px auto 10px auto',
    },
    button: {
        fontSize: 13,
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.05px',
        textTransform: 'none',
    },
};

export default typography;
