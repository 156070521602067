import React from 'react';
import { useRouter } from 'next/router';
import { Dialog, Button, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Timer from 'components/local/elements/Timer';

const useStyles = makeStyles(() => ({
    root: {
        width: 360,
        minHeight: '100%',
        backgroundColor: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '70px 0px 20px 0px',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
        padding: 20,
    },
    titleText: {
        fontSize: 60,
        color: 'gray',
    },
    title: {
        width: '74%',
        color: '#252525',
        textAlign: 'center',
        lineHeight: 1.1,
        fontSize: 14,
    },
    lessonBox: {
        borderRadius: 20,
        backgroundColor: '#d9e6ff',
        padding: '3px 14px',
        color: '#175ccc',
        marginLeft: 5,
    },
    lesson: {
        color: '#185CCC',
        fontWeight: 600,
        textAlign: 'center',
        fontSize: 12,
    },
    lessonBoxGreen: {
        borderRadius: 20,
        backgroundColor: '#c9efd7',
        padding: '3px 14px',
        // color: '#175ccc',
        marginLeft: 5,
    },
    lessonGreen: {
        color: '#0c9757',
        fontWeight: 600,
        textAlign: 'center',
        fontSize: 12,
    },
}));

const ComposeStatus = ({ handleClose, datas, open }) => {
    const classes = useStyles();
    const router = useRouter();
    // let delta = 0;
    // const [delta, setDeltaTime] = useState(0);
    // console.log(datas);

    // const duration = moment().diff(moment(datas.level.start_date), 'seconds');
    // const newDuration = datas.level.duration * 60 - duration;
    // if (newDuration <= 0) {
    //     delta = 0;
    // }

    // delta = datas.level.duration * 60 - duration;

    const onFinish = () => {
        router.push(`/student/olympiads/${datas.olympiad.id}`);
        handleClose();
        console.log('finished');
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <Box className={classes.root}>
                <Typography
                    variant="h6"
                    className={classes.title}
                    sx={{
                        marginBottom: 1,
                        width: '74%',
                        color: '#252525',
                        textAlign: 'center',
                        lineHeight: 1.1,
                        fontSize: 14,
                    }}
                >
                    {datas.olympiad.group.name}
                </Typography>
                <Typography
                    variant="h6"
                    className={classes.title}
                    style={{ color: '#899AB9', marginBottom: 5 }}
                >
                    Олимпиад эхэллээ
                </Typography>

                <Box style={{ display: 'flex', margin: 10 }}>
                    <Box className={classes.lessonBoxGreen}>
                        <Typography className={classes.lessonGreen} style={{ fontSize: 14 }}>
                            {datas.olympiad.grade.name}
                        </Typography>
                    </Box>
                    <Box className={classes.lessonBoxGreen}>
                        <Typography className={classes.lessonGreen} style={{ fontSize: 14 }}>
                            {datas.olympiad.group.lesson.name}
                        </Typography>
                    </Box>
                </Box>

                {/* <Typography className={classes.titleText} variant="h3">
                    01:30
                </Typography> */}
                <Box sx={{ marginTop: 2 }}>
                    <Timer duration={3} onEnd={onFinish} />
                </Box>
                <Box className={classes.buttons}>
                    <Button style={{ fontWeight: 600, color: '#949494' }} onClick={handleClose}>
                        Хаах
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default ComposeStatus;
