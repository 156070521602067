const PACKAGE_PAYMENT_SUCCES = 'PACKAGE_PAYMENT_SUCCES';
const PACKAGE_PAYMENT_ERROR = 'PACKAGE_PAYMENT_ERROR';
const PACKAGE_PAYMENT_PENDING = 'PACKAGE_PAYMENT_PENDING';

const initialState = {
    paymentStatus: 'pending',
};

const reducer = (state, action) => {
    switch (action.type) {
        case PACKAGE_PAYMENT_SUCCES:
            return {
                paymentStatus: 'success',
            };
        case PACKAGE_PAYMENT_ERROR:
            return { paymentStatus: 'error' };
        case PACKAGE_PAYMENT_PENDING:
            return {
                paymentStatus: 'pending',
            };
        default:
            return state;
    }
};

const packageReducer = [reducer, initialState];

export default packageReducer;
