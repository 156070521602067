import io from 'socket.io-client';
import React, { useState, useEffect, useContext } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Alert from '@mui/material/Alert';

import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import { Box, Container, Typography } from '@mui/material';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import clsx from 'clsx';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// store
import { GlobalContext } from 'context';

// utils
import useWindowSize from 'utils/useWindowSize';

// components
import Footer from 'components/local/layout/Footer';
import StatusCompose from 'components/local/compose/StatusCompose';
import StatusBar from 'components/global/helpers/StatusBar';

// actions
import { logout } from 'context/actions/auth';
import { checkProfile } from 'context/actions/accounts';

let socket;

const useStyles = makeStyles()(() => {
    return {
        root: {
            display: 'flex',
            height: 80,
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        logo: {
            backgroundImage: 'url(/admin/svg/logo.svg)',
            width: 182,
            backgroundRepeat: 'no-repeat',
            marginTop: -3,
            height: 33,
        },
        text: {
            fontSize: 11,
            maxWidth: 253,
            color: '#899AB9',
            borderLeft: '1px solid #899AB9',
            lineHeight: 1.2,
            marginLeft: 10,
            paddingLeft: 10,
            fontFamily: 'roboto, sans-serif',
        },
        menu: {
            backgroundColor: '#222C3D',
            height: 118,
            maxWidth: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        boxMenu: {
            margin: 5,
            width: 100,
            height: 70,
            borderRadius: 6,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            border: '2px solid #899AB9',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#3e5785',
            },
        },
        choosedMenu: {
            border: '2px solid #185CCC',
            backgroundColor: '#185CCC',
            '&:hover': {
                backgroundColor: '#185CCC',
            },
        },
        titleLine: {
            height: 1,
            backgroundColor: '#e7e7e7',
            width: '100%',
        },
        textMenuChoosed: {
            color: 'white',
        },
        profile: {
            display: 'flex',
            border: '2px solid #175ccc',
            padding: 5,
            borderRadius: 27,
            alignItems: 'center',
        },
        crArrow: {
            backgroundColor: '#cfcfcf',
            width: 30,
            height: 30,
            borderRadius: 32,
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginRight: 5,
            '&:hover': {
                backgroundColor: '#c7c7c7',
            },
        },
        crHover: {
            margin: '0px 10px',
            borderRadius: 4,
        },
    };
});

export default function MoreLayout({ children }) {
    const { classes } = useStyles();
    const router = useRouter();
    const { sizes } = useWindowSize();
    const [userType, setUserType] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertData, setOpenAlertData] = useState(null);
    const [status, setStatusOpen] = useState(false);
    const [profileAlert, setProfileAlert] = useState(false);
    const [userInfo, setUserInfo] = useState(true);
    const [choosedModule, setChoosedModule] = useState(router.pathname.split('/')[2]);
    const { state, dispatch } = useContext(GlobalContext);

    useEffect(() => {
        if (state.auth.loggedIn) {
            setUserType(state.auth.user.info.type);
            socketInitializer();
        } else {
            router.push('/');
        }
    }, [state.auth]);

    useEffect(() => {
        if (!state.auth.loggedIn || state.auth.user.info.type !== 'STUDENT') return;
        checkProfile().then((result) => {
            setUserInfo(result.userInfo);
            if (!result.userInfo) {
                setProfileAlert(true);
                setChoosedModule('profile');
                router.push('/student/profile');
            }
        });
    }, []);

    const socketInitializer = async () => {
        socket = io(process.env.NEXT_PUBLIC_HOST + '?auth_token=' + state.auth.user.authToken);

        socket.on('connect', () => {
            console.log('connected');
        });

        socket.on('paid', (data) => {
            console.log('paid data: ', data);
            dispatch({ type: 'PACKAGE_PAYMENT_SUCCES', data: data });
        });

        socket.on('change_status', (data) => {
            console.log('change status data: ', data);
            if (data.status === 'started') {
                setOpenAlert(true);
                setOpenAlertData(data.datas);
            } else if (data.status === 'finished') {
                setStatusOpen(true);
            }
            dispatch({ type: 'LEVEL_CHANGE_STATUS', data: data });
        });
    };

    const handleModuleClick = (key) => {
        if (!userInfo) {
            setProfileAlert(true);
            return;
        }
        setChoosedModule(key);
        if (userType === 'ADMIN') router.push('/admin/' + key);
        else if (userType === 'STUDENT') router.push('/student/' + key);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout(dispatch);
    };

    const dummy = [];
    if (userType === 'STUDENT') {
        dummy.push(
            {
                key: 'olympiads',
                title: 'Олимпиад',
                icon: (
                    <EmojiEventsRoundedIcon
                        sx={{ color: 'olympiads' === choosedModule ? 'white' : '#899AB9' }}
                    />
                ),
            },
            {
                key: 'books',
                title: 'Номнууд',
                icon: (
                    <MenuBookRoundedIcon
                        sx={{ color: 'books' === choosedModule ? 'white' : '#899AB9' }}
                    />
                ),
            },
            {
                key: 'profile',
                title: 'Профайл',
                icon: (
                    <PersonRoundedIcon
                        sx={{ color: 'profile' === choosedModule ? 'white' : '#899AB9' }}
                    />
                ),
            },
            {
                key: 'payments',
                title: 'Багц',
                icon: (
                    <ArticleRoundedIcon
                        sx={{ color: 'payments' === choosedModule ? 'white' : '#899AB9' }}
                    />
                ),
            }
        );
    } else {
        dummy.push(
            {
                key: 'olympiads',
                title: 'Олимпиад',
                icon: (
                    <EmojiEventsRoundedIcon
                        sx={{ color: 'olympiads' === choosedModule ? 'white' : '#899AB9' }}
                    />
                ),
            },
            {
                key: 'books',
                title: 'Номнууд',
                icon: (
                    <MenuBookRoundedIcon
                        sx={{ color: 'books' === choosedModule ? 'white' : '#899AB9' }}
                    />
                ),
            },
            {
                key: 'students',
                title: 'Сурагчид',
                icon: (
                    <PersonRoundedIcon
                        sx={{ color: 'students' === choosedModule ? 'white' : '#899AB9' }}
                    />
                ),
            },
            {
                key: 'news',
                title: 'Мэдээ',
                icon: (
                    <AccountBalanceWalletRoundedIcon
                        sx={{ color: 'news' === choosedModule ? 'white' : '#899AB9' }}
                    />
                ),
            }
        );
    }

    return (
        <Box>
            <StatusBar
                open={status}
                onClose={() => setStatusOpen(false)}
                message={`Олимпиад дууслаа оролцсонд баярлалаа.`}
            />
            <Container className={classes.root}>
                <Box style={{ display: 'flex' }}>
                    <Link href="/" passHref>
                        <Box className={classes.logo} style={{ cursor: 'pointer' }}></Box>
                    </Link>
                    {!sizes.sm && (
                        <Typography className={classes.text}>
                            Монголын төрөлжсөн олимпиад, уралдаан тэмцээнүүдийн нэгдсэн мэдээллийн
                            сан
                        </Typography>
                    )}
                </Box>
                {state.auth.loggedIn && (
                    <Box className={classes.profile}>
                        {state.auth.user.info.avatar && (
                            <Box
                                style={{
                                    overflow: 'hidden',
                                    borderRadius: 25,
                                    height: 40,
                                    marginRight: 5,
                                }}
                            >
                                <Image
                                    alt="avatar"
                                    src={state.auth.user.info.avatar}
                                    width={40}
                                    height={40}
                                ></Image>
                            </Box>
                        )}
                        {!sizes.sm && (
                            <Box style={{ marginLeft: 10, marginRight: 20 }}>
                                <Typography style={{ color: 'gray' }} variant="h6">
                                    {state.auth.user.info.lastName}
                                </Typography>
                                <Typography style={{ fontSize: 14, marginTop: -7 }} variant="h6">
                                    {state.auth.user.info.firstName}
                                </Typography>
                            </Box>
                        )}
                        <Box className={classes.crArrow}>
                            <KeyboardArrowDownIcon onClick={handleClick} />
                        </Box>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => router.push('/')} className={classes.crHover}>
                                <HomeRoundedIcon
                                    style={{
                                        fontSize: 18,
                                        marginRight: 10,
                                        marginLeft: -5,
                                        color: '#afafaf',
                                    }}
                                />
                                <Typography variant="h6" style={{ color: '#545454' }}>
                                    Нүүр хуудас
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleLogout} className={classes.crHover}>
                                <ExitToAppIcon
                                    style={{
                                        fontSize: 18,
                                        marginRight: 10,
                                        marginLeft: -5,
                                        color: '#afafaf',
                                    }}
                                />
                                <Typography variant="h6" style={{ color: '#545454' }}>
                                    Гарах
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
            </Container>
            <Container className={classes.menu}>
                {dummy.map((item) => {
                    const menuStyle = clsx({
                        [classes.boxMenu]: true, //always applies
                        [classes.choosedMenu]: item.key === choosedModule, //only when open === true
                        // [classes.disableMode]: item.key === choosedModule, //only when open === true
                    });
                    const menutextStyle = clsx({
                        [classes.textMenuChoosed]: item.key === choosedModule, //only when open === true
                        // [classes.textMenudisable]: item.key === choosedModule, //only when open === true
                    });
                    return (
                        <Box
                            key={item.key}
                            className={menuStyle}
                            onClick={() => handleModuleClick(item.key)}
                        >
                            {item.icon}
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={menutextStyle} variant="subtitle1">
                                    {item.title}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Container>
            {profileAlert && (
                <Alert severity="warning">
                    Бусад хэсэгрүү орохын тулд өөрийн мэдээллээ бүрэн оруулна уу!
                </Alert>
            )}
            {children}
            <Footer />
            {openAlert && openAlertData != null && (
                <StatusCompose
                    open={true}
                    datas={openAlertData}
                    handleClose={() => {
                        setOpenAlert(false);
                        setOpenAlertData(null);
                    }}
                />
            )}
        </Box>
    );
}
