import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';
import BreakPoints from './breakPoints';

const theme = createTheme({
    breakpoints: {
        values: BreakPoints,
    },
    palette: {
        background: {
            dark: '#2F4858',
            gray: '#EBEBEB',
            gradient: 'linear-gradient(65.54deg, #FE7563 12.26%, #FE7663 20.52%, #FE9368 83.36%)',
            default: colors.common.white,
            paper: colors.common.white,
            green: '#98FFAF',
            red: '#FF8282',
            blue: '#899AB9',
        },
        borders: {
            green: '#81DF96',
            red: '#EB6969',
            gray: '#CFCFCF',
        },
        primary: {
            main: '#2F4858',
            light: colors.common.white,
        },
        secondary: {
            main: '#CFCFCF',
            background: '#2F4858',
            light: colors.common.white,
            contrastText: colors.common.white,
        },
        text: {
            main: '#656565',
            primary: '#656565',
            secondary: colors.blueGrey[600],
            black: 'black',
            darkGray: '#4D4D4D',
            bttnGray: '#575757',
            lightBlue: '#899AB9',
            gray: '#666666',
            lightGray: '#BDBFD8',
            green: '#3C7129',
            red: '#E02B2F',
            white: '#FFFFFF',
            yellow: '#FC902D',
            orange: '#C1854D',
        },
    },
    shadows,
    typography,
});

// theme = responsiveFontSizes(theme);

export default theme;
