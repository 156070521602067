const LEVEL_CHANGE_STATUS = 'LEVEL_CHANGE_STATUS';

const initialState = {
    status: 'pending',
};

const reducer = (state, action) => {
    switch (action.type) {
        case LEVEL_CHANGE_STATUS:
            return {
                status: action.data.status,
            };
        default:
            return state;
    }
};

const olympiadReducer = [reducer, initialState];

export default olympiadReducer;
