import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';

const useStyles = makeStyles()(() => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 50,
            padding: 34,
            textAlign: 'center',
        },
        line: {
            height: 1,
            width: '100%',
            backgroundColor: '#d9d9d9',
            marginTop: 8,
        },
        imgBox: {
            backgroundImage: 'url(/admin/svg/logo.svg)',
            minWidth: 182,
            backgroundRepeat: 'no-repeat',
            margin: '0px 20px',
            height: 33,
        },
        iconBox: {
            color: '#899AB9',
            margin: '10px 4px',
            padding: '3px 14px',
            borderRadius: 20,
            fontSize: 12,
            display: 'flex',
            width: 'fit-content',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#d9e6ff',
            },
        },
    };
});

const Footer = ({}) => {
    const { classes } = useStyles();

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Box
                style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box className={classes.line}></Box>
                <Box className={classes.imgBox}></Box>
                <Box className={classes.line}></Box>
            </Box>

            <Typography
                variant="subtitle1"
                style={{ color: '#b0b0b0', maxWidth: 320, lineHeight: 1.4, margin: '10px 20px' }}
            >
                Улаанбаатар хот, Хан-Уул дүүрэг, 1-р хороо, 19-11. тоот
            </Typography>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Link href="www.facebook.com" underline="none">
                    <a href="https://www.facebook.com/olympiad.mn" target="_blank" rel="noreferrer">
                        <Box className={classes.iconBox}>
                            <FacebookIcon fontSize="small" />
                            <Typography
                                variant="subtitle1"
                                style={{ color: '#899AB9', marginLeft: 4 }}
                            >
                                Facebook
                            </Typography>
                        </Box>
                    </a>
                </Link>
                <Box className={classes.iconBox}>
                    <YouTubeIcon fontSize="small" />
                    <Typography variant="subtitle1" style={{ color: '#899AB9', marginLeft: 4 }}>
                        Youtube
                    </Typography>
                </Box>
                <Box className={classes.iconBox}>
                    <TwitterIcon fontSize="small" />
                    <Typography variant="subtitle1" style={{ color: '#899AB9', marginLeft: 4 }}>
                        Twitter
                    </Typography>
                </Box>
            </Box>
            <Typography
                variant="subtitle1"
                style={{ color: '#b0b0b0', maxWidth: 320, lineHeight: 1.4 }}
            >
                © 2024 Зохиогчийн эрх хуулиар хамгаалагдсан.
            </Typography>
        </Container>
    );
};

export default Footer;
