import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
    const [open, setOpen] = useState(props.open ? props.open : false);

    useEffect(() => {
        setOpen(props.open);
    }, [props]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        if (props.onClose) props.onClose();
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={1500}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={handleClose}
        >
            <Alert severity={props.type} sx={{ width: '100%' }}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}

CustomizedSnackbars.defaultProps = {
    open: false,
    type: 'success',
    message: 'Үйлдлийг амжилттай гүйцэтгэлээ',
};
