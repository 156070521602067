export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

import { post, put } from 'utils/fetchRequest';

export const login = async (dispatch, data) => {
    try {
        const result = await post('accounts/login', data);
        if (result.code === 200) dispatch({ type: LOGIN_SUCCESS, data: result.data });
        else dispatch({ type: LOGIN_ERROR });

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const facebookLogin = async (dispatch, data) => {
    try {
        const result = await post('accounts/facebook_auth', data);
        if (result.code === 200) dispatch({ type: LOGIN_SUCCESS, data: result.data });
        else dispatch({ type: LOGIN_ERROR });

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const logout = (dispatch) => {
    dispatch({ type: LOGOUT });
};

export const forgotEmail = async (data) => {
    try {
        const result = await post('accounts/findme', data);
        return result;
    } catch (err) {
        return { error: err };
    }
};

export const confirmCodeCheck = async (data) => {
    try {
        const result = await post('accounts/confirm_change_pass_code', data);
        return result;
    } catch (err) {
        return { error: err };
    }
};

export const setNewPassword = async (dispatch, data) => {
    try {
        const result = await put('accounts/change_password', data);
        if (result.code === 200) dispatch({ type: LOGIN_SUCCESS, data: result.data });
        else dispatch({ type: LOGIN_ERROR });
        return result;
    } catch (err) {
        return { error: err };
    }
};
